.App {
  text-align: center;
  /* background-color: #eee; */
  height:100vh;
}

.job .card {
  border-radius: 10px;
  margin-top:10px;
}
.job .card-body {
  padding:0;
  border-radius: 10px;
}

.designation-experience {
  /* display: flex; */
  justify-content: space-between;
  padding: 0 10px;
  font-size: 20px;
  color: #212529;
  text-align:left;
}


.title {
  margin-bottom: 0.5rem;
  background: #54c1fc;
  padding: 10px 10px;
  border-radius: 10px 10px 0px 0;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
}
.card-body svg {
  color: #54c1fc;
  margin-right: 5px;
}

.jd {
  list-style: none;
  text-align: left;
}

.jd label {
  font-size: 20px;
  font-weight: 500;
  padding-right: 10px;
  color: #1c1c1c;
}

.jd .value {
  font-weight: 400;
  font-size: 18px;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
}

.dash-button-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dash-button {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 5px #ccc;
  background-color: #4ac0fe;
}
.dash-button + .dash-button {
  /* margin-top: 1rem; */
}
.dash-button:active {
  box-shadow: none;
}
.dash-button a {
  color: #0d0d0d;
  text-decoration: none;
}

.flex-coloumn{
flex-direction: column;
}
.justify-content-evenly{
  justify-content: space-evenly;
}